var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "companyStaff-container" },
    [
      _c("div", { staticClass: "filter-panel" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          staticClass: "cs-input",
          staticStyle: { width: "220px" },
          attrs: { type: "text", placeholder: "搜索姓名/手机号" },
          domProps: { value: _vm.search },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            },
          },
        }),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            staticStyle: {
              "margin-left": "10px",
              "padding-left": "10px",
              "padding-right": "10px",
            },
            on: {
              click: function ($event) {
                return _vm.searchData()
              },
            },
          },
          [_vm._v(" 查询 ")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "result-panel" },
        [
          _c("CSTable", {
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", { staticClass: "table-header-panel" }, [
                      _c("div", [
                        _c(
                          "svg",
                          {
                            staticClass: "icon",
                            staticStyle: {
                              "font-size": "16px",
                              "margin-right": "8px",
                            },
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-menua-zu92" },
                            }),
                          ]
                        ),
                        _vm._v(" 只显示已注册且所属企业为本公司的人员。 "),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "thead",
                fn: function () {
                  return [
                    _c("tr", [
                      _c("th", [_vm._v("姓名")]),
                      _c("th", [_vm._v("性别")]),
                      _c("th", [_vm._v("手机号")]),
                      _c("th", [_vm._v("身份证号")]),
                      _c("th", [_vm._v("昵称")]),
                      _c("th", [_vm._v("头像")]),
                      _c("th", [_vm._v("通行记录")]),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "tbody",
                fn: function () {
                  return _vm._l(_vm.companyList, function (item) {
                    return _c("tr", { key: item.id }, [
                      _c("td", [_vm._v(_vm._s(item.realName))]),
                      _c("td", [_vm._v(_vm._s(_vm.genderObj[item.gender]))]),
                      _c("td", [_vm._v(_vm._s(item.phone))]),
                      _c("td", [
                        _vm._v(_vm._s(item.idCard ? item.idCard : "-")),
                      ]),
                      _c("td", [_vm._v(_vm._s(item.nickName))]),
                      _c("td", { staticClass: "imgAva" }, [
                        _c("img", {
                          attrs: { src: item.avatar },
                          on: {
                            click: function ($event) {
                              return _vm.checkAvaBtn(item.avatar)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "span",
                          {
                            staticClass: "allow-click",
                            on: {
                              click: function ($event) {
                                return _vm.lookCheckInLog(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]),
                    ])
                  })
                },
                proxy: true,
              },
            ]),
          }),
          _c("Pagination"),
        ],
        1
      ),
      _c("CSDialog", {
        attrs: {
          dialogWidth: 380,
          dialogVisible: _vm.checkAva,
          "dialog-show-confirm-btn": false,
          dialogTitle: "头像",
          dialogCancelBtnText: "关闭",
        },
        on: {
          onClose: function ($event) {
            _vm.checkAva = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "dialog-content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "320px",
                      height: "320px",
                      margin: "30px",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "320px", height: "320px" },
                      attrs: { src: _vm.checkAvaSrc, alt: "" },
                    }),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }