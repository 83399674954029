<template>
    <div class="companyStaff-container">
        <div class="filter-panel">
            <input
                    type="text"
                    class="cs-input"
                    style="width: 220px"
                    placeholder="搜索姓名/手机号"
                    v-model="search"
            />
            <button
                    class="btn btn-primary"
                    style="margin-left: 10px;padding-left: 10px;padding-right: 10px"
                    @click="searchData()"
            >
                查询
            </button>
        </div>
        <div class="result-panel">
            <CSTable>
                <template v-slot:header>
                    <div class="table-header-panel"
                    >
                        <div>
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="font-size: 16px; margin-right: 8px"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            只显示已注册且所属企业为本公司的人员。
                        </div>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>姓名</th>
                        <th>性别</th>
                        <th>手机号</th>
                        <th>身份证号</th>
                        <th>昵称</th>
                        <th>头像</th>
                        <th>通行记录</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in companyList" :key="item.id">
                        <td>{{item.realName}}</td>
                        <td>{{ genderObj[item.gender]}}</td>
                        <td>{{item.phone}}</td>
                        <td>{{item.idCard?item.idCard:'-'}}</td>
                        <td>{{item.nickName}}</td>
                        <td class="imgAva">
                            <img :src="item.avatar" @click="checkAvaBtn(item.avatar)">
                        </td>
                        <td>
                            <span class="allow-click" @click="lookCheckInLog(item)">查看</span>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination/>
        </div>
        <!--  查看头像-->
        <CSDialog
                :dialogWidth="380"
                :dialogVisible="checkAva"
                @onClose="checkAva = false"
                :dialog-show-confirm-btn="false"
                dialogTitle="头像"
                dialogCancelBtnText="关闭"

        >
            <template v-slot:dialog-content>
                <div
                        style="
          width: 320px;
          height: 320px;
          margin: 30px;
        "
                >
                    <img
                            :src="checkAvaSrc"
                            alt=""
                            style="width: 320px;; height: 320px"
                    />
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
    import CSTable from "@/components/common/CSTable";
    import Pagination from "@/components/Pagination";
    import {getCompanyByUserUrl} from '@/requestUrl';
    import vc from "../../../common/core";
    import CSDialog from "@/components/common/CSDialog";

    export default {
        name: 'companyStaff',
        data() {
            return {
                search: "",
                companyList: [],
                checkAva: false,
                checkAvaSrc: "",
                genderObj: {
                    1: '男',
                    2: '女',
                    0: '未知'
                }
            }
        },
        created() {
            this.searchData();
            window.addEventListener('keydown', this.searchDataEnd)
        },
        mounted() {
            this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
                this.searchData(pageNo);
            });
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.searchDataEnd)
        },
        deactivated() {
            window.removeEventListener('keydown', this.searchDataEnd)
        },
        methods: {
            // 查看图片
            checkAvaBtn(checkAvaSrc) {
                this.checkAvaSrc = checkAvaSrc;
                this.checkAva = true;
            },
            lookCheckInLog(item) {
                this.$router.push({
                    name:'employeeAccess',
                    query:{
                        item
                    }
                })
            },
            //回车查询列表
            searchDataEnd(e) {
                if (e.keyCode == 13) {
                    this.searchData();
                }
            },
            //查询列表
            searchData(pageNo = 1, pageSize = 10) {
                this.$fly.post(getCompanyByUserUrl, {
                    userId: this.$vc.getCurrentStaffInfo().id,    //当前登录用户ID
                    pageSize,  //页面大小
                    pageNo,    //页码
                    search: this.search     //搜索框内容
                }).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    if (pageNo == 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize: pageSize
                        });
                    }
                    this.companyList = res.data.datas;
                })
            }
        },
        components: {
            CSTable,
            Pagination,
            CSDialog
        }
    }
</script>

<style lang="stylus" scoped>


    .imgAva img {
        width: 60px;
        height 60px;
        cursor pointer
    }
</style>